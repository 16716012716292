import React from 'react'
import {education} from './Education';
import {profile, profilePicture, careerHighlights, competencies} from './Profile';
import {employment} from './EmploymentHistory';
import { guideColors } from "./StyleObjects";

export const jumbo = (objectName) => {
  return (
    <div
      className="jumbotron jumbotron-fluid"
      style={{
      backgroundColor: `${guideColors.header}`
    }}>
      <div className="container">
        <h1 className="display-4">{objectName.name}</h1>
        <p className="lead">{objectName.subHead}</p>
      </div>
    </div>
  )
};

export const insertProfilePicture = (objectName = profilePicture) => {
  return (
    <img
      style={{
      width: '30%',
      float: 'right',
      marginRight: '2em',
      borderRadius: '80%',
      paddingLeft: "2em",
      zIndex: 10,
    }}
      alt={objectName.alt}src={objectName.url} ></img>
  );
};

export const educationProfile = (objectName = education) => {
  return (
    <div className="Education">
      {objectName.map(item => {
        return (
          <div className={item.institution}>
            <h2>{item.institution}</h2>
            <h3>{item.qualification}</h3>
            <h4>{`${item.startYear}
              - ${item.endYear}`}</h4>
            <ul>
              {item
                .highlights
                .map((value, index) => {
                  return <li key={index}>{value}</li>
                })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export const blurb = (objectName = profile) => {
  return (
    <div className="Blurb">
      {objectName
        .about
        .map((scentence, i) => {
          return <p key={i}>{scentence}</p>
        })}
    </div>
  )
}

export const careerParagraph = (objectName = careerHighlights) => {
  return (
    <div className="CareerHighlights">
      <h2 style={{
        paddingBottom: '0.4em'
      }}>{objectName.name}</h2>
      <ul>
        {objectName
          .about
          .map((point, i) => {
            return <li key={i}>{point}</li>
          })}</ul>
    </div>
  )
}

export const workCompetencies = (objectName = competencies) => {
  return (
    <div className="Competencies">
      <h2
        style={{
        paddingTop: '0.8em',
        paddingBottom: '0.4em'
      }}>{objectName.name}</h2>
      <ul>
        {objectName
          .about
          .map((point, i) => {
            return <li key={i}>{point}</li>
          })}
      </ul>
    </div>
  )
}

export const employmentFunction = (objectName = employment) => {
  return (
    <div>
      {objectName.map(job => {
        return (
          <div className="Employment">
            <div className={job.jobTitle}>
              <h2>{job.jobTitle}</h2>
              <h3>{job.company}</h3>
              <h4>{`${job.startDate}
                - ${job.endDate}`}</h4>
              
                
                { job.blurb ? 
                  <div>
                    <h3>Summary</h3>
                    <p>{job.blurb}</p>
                    <h3>Key Responsibilities</h3>
                    <ul className={job.jobTitle}>
                  {job
                  .duties
                  .map((value, index) => {
                    return <li key={index}>{value}</li>} )}
                    </ul>
                  <h3>Key Achievements</h3>
                  
                    {job.achievements ? 
                      <ul className={job.jobTitle}>{
                        job.achievements.map((value, index) => {
                          return <li key={index}>{value}</li>
                        })
                      }</ul> : null} </div>
                  :
                  <ul className={job.jobTitle}>
                  {job
                  .duties
                  .map((value, index) => {
                    return <li key={index}>{value}</li>} )}
                  </ul>
                   }
            </div>
          </div>
        )
      })}</div>
  )
}