import React, {Component} from 'react'
import {navLinks} from '../../JS/pageLinks';
import { guideColors } from "../../JS/StyleObjects";
import './NavBar.scss';

export class NavBar extends Component {
  render() {
    return (
      <div className="NavBar sticky-top">
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{
          backgroundColor: `${guideColors.navBar}`
        }}>
          <a className="navbar-brand" href={`${navLinks[0].hash}${navLinks[0].link}`}>Jonathan Brett</a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {navLinks.map(item => {
                return <li className="nav-item" key={item.name}>
                  <a className="nav-link" href={`${item.hash}${item.link}`}>{item.name}</a>
                </li>;
              })
}
            </ul>
          </div>
        </nav>
      </div>

    )
  }
}
