import React from 'react';
import { Footer } from '../Nav/Footer/Footer';
import { NavBar } from '../Nav/NavBar/NavBar';
import { Profile } from "../Profile/Profile";


export const ProfilePage = () => {

    return (
        <div>
            <NavBar />
            <Profile />
            <Footer />
        </div>

    );
}