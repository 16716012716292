import React, { Component } from 'react';
import { jumboTron } from '../JS/Education';
import { jumbo, educationProfile } from "../JS/ElementFunctions";
import './Education.scss';


export class Education extends Component {
    render() {
        return (
            <div className="EducationBackground">
                {jumbo(jumboTron)}
                {educationProfile()})
            </div>
        );
    }
}

