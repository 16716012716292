import picture from '../../util/images/profileImage.jpg';
import { about, fullName } from "./personalInfo";

export const profilePicture = {
    alt: `${fullName}`,
    url: picture,
};

export const profile = {
    name: "About Me",
    about: [
    `A ${about.currentTitle} with experience teaching secondary
    mathematics and a degree in Civil and Structural
    Engineering, with 7 years’ experience teaching mathematics
    to the ages of 11 to 18.`,
    `A team player with vast experience working in groups to
    achieve a common goal.`
    ],
};

export const careerHighlights = {
    name: "Career Highlights",
    about: [
    `Worked in North Carolina, USA as part of the Educational
    Partners International LLC teacher exchange programme.`,
    `Delivered ICT training and support representing my
    company for external training.`,
    `Created and maintained my own digital platform for
    colleagues and students.`,
    `Created spreadsheet platforms for whole school data
    tracking.`,
    ],
};

export const competencies = {
    name: "Competencies",
    about: [
    `Created spreadsheet platforms for whole school data
    tracking.`,
    `Familiarity with JavaScript, Java, Ruby, C# and more
    including frameworks such as React, Vue, PhaserJS and
    more.`,
    `Experience working with Linux, MacOS, Windows, Bash and
    Git.`,
    `Extensive understanding of mathematics, capable of
    creating and understanding complex calculations,
    numerical data, statistics and using logic to form and solve
    problems.`,
    `Excellent communicator clear at expressing thoughts and
    an open listener.`,
    `Deadline management`,
    ], 
};

export const jumboInfo = {
    name: `${profile.name}`,
    subHead: '',
};