import React from 'react';
import { Footer } from '../Nav/Footer/Footer';
import { NavBar } from '../Nav/NavBar/NavBar';
import { Education } from '../Education/Education';


export const EducationHistory = () => {

    return (
        <div>
            <NavBar />
            <Education />
            <Footer />
        </div>

    );
}