

export const colouring = {
    first: '#e3f2fd',
    second: '#9fc7e4',
    third: '#65a0ca',
};

export const guideColors = {
    navBar: colouring.second,
    footer: colouring.first,
    pageBackground: colouring.first,
    header: colouring.second,
};