import React from 'react';
import { Footer } from '../Nav/Footer/Footer';
import { NavBar } from '../Nav/NavBar/NavBar';
import { HomePage } from "../HomePage/HomePage";


export const MainPage = () => {

    return (
        <div>
            <NavBar />
            <HomePage />
            <Footer />
        </div>

    );
}