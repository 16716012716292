export const jumboTron = {
    name: 'Education',
    subHead: '',
};

export const education = [{
        institution: 'The University of Sheffield',
        qualification: 'PGCE Secondary Mathematics (Ages 11-18)',
        startYear: 2012,
        endYear: 2013,
        highlights: [/*'Achieved Qualified Teacher Status'*/],
        attainment: [],
    },{
        institution: 'The University of Bradford',
        qualification: 'BEng(Hons) Civil and Structural Engineering',
        startYear: 2009,
        endYear: 2012,
        highlights: [/*'2:2'*/],
        attainment: [
                {
                "First Year": {
                    'Materials Technology': 68,
                    'Visualisation': 46.4,
                    'Environmental Systems': 61,
                    'Design Studies': 50,
                    'Engineering Computation': 68,
                    'Engineering Analysis(Civil)': 52.1,
                    'Geotechnical Materials': 55,
                    'Surveying': 51.5,
                    'Fluid Mechanics 1': 50,
                    'Structural Mechanics': 64.3,
            }}, {
                'Second Year': {
                    'Concrete Technology': 67.5,
                    'Soil Mechanics 1': 52.9,
                    'Structural and Solid Mechanics': 61,
                    'Fluid Mechanics 2': 75,
                    'Structural Steelwork Design': 63,
                    'Further Engineering Analysis(Civil)': 55.9,
                    'Stage 2 Project (Civil)': 55.9,
                    'Computer Modelling Techniques': 74.5,
                    'Enginnering Statistics': 64.2,
                    'Structural Analysis': 51.8,
                    'Open Channel Hydraulics': 65,
                    'Structural Concrete Design': 37,
            }},{
                'Third Year': {
                    'Soil Mechanics 2': 57,
                    'Feasibility Study': 62,
                    'Project Management': 68,
                    'Transportation Studies': 60,
                    'Dissertation': 67,
                    'Civil Engineering Design': 53,
                    'Steel and Composite': 70,
                    'Sustainable Hydraulic Design': 36,
            }},],
    },{
        institution: 'Wath Comprehensive School: A Language College',
        qualification: 'A-Levels',
        startYear: 2006,
        endYear: 2009,
        highlights: ['5 A-Levels in Chemistry, Physics, Maths, Biology and General Studies'],
        attainment: [{
            'Maths': 'C',
            'Chemistry': 'D',
            'General Studies': 'D',
            'Physics': 'E',
            'Biology(AS)': 'B',
        }],
    },{
        institution: 'Pope Pius X: Roman Catholic High School',
        qualification: `GCSE's`,
        startYear: '2001',
        endYear: '2006',
        highlights: ['11 A*-C including Maths and English'],
        attainment: [{
            'Media Studies': "A",
            'Biology': 'B',
            'Chemistry': 'C',
            'Physics': 'C',
            'Mathematics': 'B',
            'Statistics': 'B',
            'English Language': 'C',
            'English Literature': 'B',
            'History': 'B',
            'D&T: Food Technology': 'C',
            'Religious Studies': 'C',
            'Spanish': 'D',
            'Key Skills ICT': 'Level 2',
        }],
    },
];