import React, { Component } from 'react';
import { jumboInfo } from '../JS/EmploymentHistory';
import './Employment.scss';
import { jumbo, employmentFunction } from "../JS/ElementFunctions";


export class Employment extends Component {
    render() {
        return (
            <div className="EmploymentHistory">
                    {jumbo(jumboInfo)}
                    {employmentFunction()}
            </div>
        )
    }
}
