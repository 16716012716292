import React, { Component } from 'react'
import { jumboInfo } from '../JS/personalInfo';
import { jumbo } from "../JS/ElementFunctions";

export class Header extends Component {
    render() {
        return (
            <div className="Header">
                {jumbo(jumboInfo)}
            </div>

        )
    }
}
