import React, {Component} from 'react'
import { jumboInfo } from "../JS/Profile";
import { jumbo, blurb, careerParagraph, workCompetencies, insertProfilePicture } from "../JS/ElementFunctions";
import './Profile.scss';

export class Profile extends Component {
  render() {
    return (
      <div className="Profile">
        {jumbo(jumboInfo)}
        {insertProfilePicture()}
      <div className="Info">
        {blurb()}
        {careerParagraph()}
        {workCompetencies()}
      </div>
      </div>
    )
  }
}
