import { EmploymentHistory } from '../pages/EmploymentHistory';
import { MainPage } from '../pages/index';
import { EducationHistory } from '../pages/EducationHistory';
import { ProfilePage } from "../pages/Profile";

export const repoName = '';
//export const hash = ``;
export const hash = `${repoName}/#`;

export const emailAddress = 'jonathanacbrett@gmail.com';

export const navLinks = [
    {
        name: 'Home',
        link: '/',
        objectClass: 'Home',
        pageName: MainPage,
        hash: hash,
    },{
        name: 'About',
        link: '/about',
        objectClass: '',
        pageName: ProfilePage,
        hash: hash,
    },{
        name: 'Employment',
        link: '/employment',
        objectClass: 'EmploymentHistory',
        pageName: EmploymentHistory,
        hash: hash,
    },{
        name: 'Education',
        link: '/education',
        objectClass: 'EducationHistory',
        pageName: EducationHistory,
        hash: hash,
    },{
        name: 'Portfolio',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: hash,
    },{
        name: 'Contact',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: hash,
    },

    
];

export const socialLinks = [
        {
            site: 'Twitter',
            iconClass: 'fa-twitter',
            objectClass: 'fa',
            link: 'https://twitter.com/jonathanbrett',
            logoColor: '#55ACEE',
        },/*{
            site: 'Facebook',
            iconClass: 'fa-facebook',
            objectClass: 'fa',
            link: '',
            logoColor: '#3B5998',
        },{
            site: 'Reddit',
            iconClass: 'fa-reddit',
            objectClass: 'fa',
            link: '',
            logoColor: '#ff5700',
        },{
            site: 'Pinterest',
            iconClass: 'fa-pinterest',
            objectClass: 'fa',
            link: '',
            logoColor: '#cb2027',
        },{
            site: 'Instagram',
            iconClass: 'fa-instagram',
            objectClass: 'fa',
            link: '',
            logoColor: '#125688',
        },*/{
            site: 'YouTube',
            iconClass: 'fa-youtube',
            objectClass: 'fa',
            link: 'https://www.youtube.com/channel/UCsEvk-b7V-GAUgMHQ2iTmxQ',
            logoColor: '#bb0000',
        },{
            site: 'GitHub',
            iconClass: 'fa-github',
            objectClass: 'fa',
            link: `https://github.com/MopishHobgoblin`,
            logoColor: '#ffffff',
        },
        {
            site: 'Email',
            iconClass: 'fa-envelope',
            objectClass: 'fa',
            link: `mailto:${emailAddress}`,
            logoColor: '#00b489',
        },
];