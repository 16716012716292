export const jumboInfo = {
    name: 'Employment History',
    subHead: '',
};

export const employment = [
    {
        jobTitle: 'Learning and Development Officer',
        company: 'Barnsley Council',
        startDate: 'September 2020',
        endDate: 'Present',
        blurb: `Working as part of Human Resources as part of the Organisation and Workforce development team, I work directly with different teams, departments and business to help create, maintain and monitor learning and development needs and requirements.`,
        duties: [
            `Work closely with the Learning and Development Co-Ordinator, Business Partners and Customers to craft and develop training plans and resources to support the requirements of teams and the business as a whole.`,
            `Use the learning management system (LMS) to identify and analyse training needs throughout the organisation.`,
            `Design and deliver captivating and engaging training courses on a variety of different topics in line with service requirements.`,
            `Using different methods of delivery, such as in-person, Microsoft Teams, Skype, Zoom, eLearning or by creating instructional videos to ensure training needs are met effectively.`,
        ],
        achievements: [
            `Designed and developed a course on ensuring content developed within the business are accessible to customers and employees with accessibility requirements.`,
            `Designed, developed and delivered training for COVID testing centres within Barnsley and its surrounding areas.`,
            `Created modern accessible templates for all training materials developed within our wider team meets and exceeds current accessibility regulations. This made all of our content accessible and cut development time on training significantly. This work is currently still being rolled out to the entire organisation.`,

        ],
    },{
        jobTitle: 'Supply Teacher',
        company: 'Vision for Education',
        startDate: 'December 2019',
        endDate: 'September 2020',
        duties: [
            `Adapted to any situation required to uphold a safe, engaging and effective learning environment.`,
            `Deliver existing materials and instructions effectively as outlined by lesson plans.`,
        ],
    },{
        jobTitle: 'Teacher of Mathematics',
        company: 'Ashbrook High School',
        startDate: 'August 2016',
        endDate: 'August 2019',
        duties: [
            `Selected as one of the pioneering 50 teachers from the county for work incorporating technology to make content delivery exciting, effective and engaging.`,
            `Worked to improve and standardise content within the county delivering staff development training for over 60 schools.`,
        ],
    },{
        jobTitle: 'Sales Associate',
        company: 'Company Shop LTD.',
        startDate: 'July 2016',
        endDate: 'August 2016',
        duties: [
            'Maintain good customer service at all times',
            'Manage and maintain workload to meet customer demand',
        ],
    },{
        jobTitle: 'Teacher of Mathematics, Lead Intervention Tutor',
        company: 'Netherwood ALC',
        startDate: 'June 2013',
        endDate: 'August 2016',
        duties: [
            `Utilised data to identify learning needs, designing, adapting and delivering intervention sessions around this data.`,
            `Department lead on alternative provision and champion for planning for multiple learning types.`,
            `Department lead on the addoption of ICT to further learning opportunities.`,
        ],
    },{
        jobTitle: 'Leisure Attendant',
        company: 'Barnsley Premier Leisure',
        startDate: 'September 2006',
        endDate: 'November 2012',
        duties: [
            `Use customer service and communication skills to ensure that a good customer focussed experience is achieved.`,
        ],
    },
];