
export const about = {
    firstName: 'Jonathan',
    lastName: 'Brett',
    email: 'admin@jonathanbrett.dev',
    website: 'https://jonathanbrett.dev',
    teacherTitle: 'Teacher of Mathematics',
    programmerTitle: 'Developer',
    currentTitle: 'Learning and Development Officer',
    city: 'Barnsley',
    country: 'UK',
}

export const fullName = `${about.firstName} ${about.lastName}`;

export const subHeading = `${about.currentTitle}, ${about.programmerTitle}`;

export const location = `${about.city}, ${about.country}`;

export const jumboInfo = {
    name: fullName,
    subHead: subHeading,
};
