import React, {Component} from 'react';
import {Header} from "../Header/Header";
import {insertProfilePicture, blurb} from "../JS/ElementFunctions";

export class HomePage extends Component {
  render() {
    return (
      <div className="HomePage">
        <Header/> 
        <div className="Info">
          {insertProfilePicture()}
          {blurb()}
        </div>
      </div>
    );
  }
}
